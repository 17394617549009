<template>
  <div class="">
    <input
      ref="filesInput"
      type="file"
      :multiple="multiple"
      class="hidden"
      accept="image/*"
      @change="handleFilesChange"
    />
    <div
      ref="uploadField"
      class="max-w-[300px] p-1 border-4 border-dashed border-sky-500 flex space-x-2 items-center shadow-lg cursor-pointer hover:bg-sky-100 transition"
      :class="{ 'bg-gray-300 hover:bg-gray-300 cursor-not-allowed': disabled }"
      @click="triggerFilesInput"
      @dragover.prevent="dragOver"
      @dragenter.prevent="dragEnter"
      @dragleave.prevent="dragLeave"
      @drop.prevent="handleDrop"
    >
      <img src="@/images/icons/add_image.png" alt="Upload images" class="w-1/3" />
      <div>
        <div class="text-sky-600 text-lg">{{ title }}</div>
        <div class="italic">(Select or drag here)</div>
      </div>
    </div>

    <div class="mt-2 flex space-x-2 w-full max-w-[300px]">
      <input
        v-model="imageUrl"
        type="url"
        placeholder="Or enter image URL"
        class="input-field flex-grow"
        :disabled="disabled"
        @keydown.enter="handleUrlUpload"
      />
      <button class="btn btn--primary" :disabled="disabled" @click="handleUrlUpload">Add</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useFeatureStore } from '%/stores/feature'
import { useNotify } from '&/composables/useNotify'

const emit = defineEmits(['uploaded'])

const props = withDefaults(
  defineProps<{
    multiple?: boolean
    disabled?: boolean
  }>(),
  {
    multiple: true,
    disabled: false
  }
)
const featureStore = useFeatureStore()
const { pushError } = useNotify()
const filesInput = ref(null)
const imageUrl = ref('')
const uploadField = ref(null)
const title = computed(() => (props.multiple ? 'Add images' : 'Add image'))

onMounted(() => {
  uploadField.value.style.background = '#fff'
})

function triggerFilesInput() {
  if (props.disabled) return
  filesInput.value.click()
}

function handleFilesChange(event) {
  const files = Array.from(event.target.files)
  processFiles(files)
}

function dragOver(event) {
  event.preventDefault() // Necessary to allow the drop
  if (props.disabled) return
  uploadField.value.style.background = 'lightgreen'
}

function dragEnter(event) {
  event.preventDefault() // Highlight potential drop target
  if (props.disabled) return
  uploadField.value.style.background = 'lightgreen'
}

function dragLeave(event) {
  event.preventDefault() // Unhighlight potential drop target
  if (props.disabled) return
  uploadField.value.style.background = '#fff'
}

function handleDrop(event) {
  uploadField.value.style.background = '#fff'
  if (props.disabled) return
  const files = Array.from(event.dataTransfer.files)
  processFiles(files)
}

function handleUrlUpload() {
  if (!imageUrl.value) return

  fetch(imageUrl.value)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Invalid image URL')
      }
      return response.blob()
    })
    .then((blob) => {
      let extension = blob.type.split('/')[1]

      if (extension === 'octet-stream') {
        // Fallback: Try to extract the extension from the URL
        const urlExtensionMatch = imageUrl.value.match(/\.\w+$/)
        if (urlExtensionMatch) {
          extension = urlExtensionMatch[0].slice(1) // Get the extension without the dot
        } else {
          extension = 'jpg' // Default fallback
        }
      }

      const uniqueId = Date.now() + '-' + Math.floor(Math.random() * 1000)
      const file = new File([blob], `image-from-url-${uniqueId}.${extension}`, { type: blob.type })
      processFiles([file])
      imageUrl.value = ''
    })
    .catch((error) => {
      pushError(error.message)
    })
}

function processFiles(files) {
  if (files.length > 0) {
    featureStore.images.push(...files)
    filesInput.value.value = null
    emit('uploaded')
  }
}
</script>
