<template>
  <div v-if="ready" class="feature">
    <FeatureMeta :feature="featureStore.feature" />

    <div v-if="featureStore.images.length === 0">
      <FeatureInitSection :feature="featureStore.feature" :multiple="false" />
      <PagePhotoToAnime />
    </div>

    <div v-else class="h-full">
      <LayoutDefault :autorun="!route.query.keep_uploaded" @finished="onFinished">
        <template #aside>
          <FeaturePhotoToAnimeOptions class="mt-4" />
        </template>
      </LayoutDefault>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '&/composables/useFeatureSetup'
import PagePhotoToAnime from '&/landing_pages/PagePhotoToAnime.vue'
import FeatureMeta from '&/FeatureMeta.vue'
import FeaturePhotoToAnimeOptions from '&/FeaturePhotoToAnimeOptions.vue'

const { ready, route, router, featureStore, LayoutDefault, FeatureInitSection } = useFeatureSetup()

function onFinished() {
  router.push({ name: 'PhotoToAnimeResult' })
}
</script>
