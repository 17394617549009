<template>
  <article class="feature-page">
    <div class="max-w-4xl mx-auto px-4 py-10">
      <h2 class="text-2xl lg:text-3xl font-bold text-center mb-4">
        piceditor.io Photo to Anime: Transform Your Photos into Stunning Anime Art
      </h2>
      <p class="text-xl text-center mb-6">Easily Convert Your Photos into Vibrant Anime-Style Art</p>
      <p class="mb-4">
        Discover the magic of anime by transforming your photos into captivating anime-style artwork. Whether you're
        looking to create unique avatars, fun social media posts, or simply explore the world of anime, our Photo to
        Anime tool offers a seamless and creative experience. With advanced AI technology, your images are converted
        into detailed and vibrant anime-style art, capturing the essence of anime while retaining the uniqueness of your
        original photo.
      </p>

      <h3 class="text-2xl font-bold mt-12 mb-6">Why Use Photo to Anime?</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 feature-page__specific">
        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/cartoon.png" alt="anime transformation" />
          <strong>Anime Transformation</strong>
          <p>
            Turn any photo into a striking anime-style image, perfect for avatars, social media, or creative projects.
          </p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/ai.png" alt="AI-powered" />
          <strong>AI-Powered Conversion</strong>
          <p>
            Utilize cutting-edge AI technology to achieve highly detailed and accurate anime-style art from your photos.
          </p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/creative.png" alt="enhance creativity" />
          <strong>Unleash Your Creativity</strong>
          <p>Explore new creative possibilities by turning ordinary photos into extraordinary anime art.</p>
        </div>
      </div>

      <h3 class="text-2xl font-bold mt-12 mb-6">How It Works</h3>
      <ol class="list-decimal pl-5 mb-6">
        <li><strong>Upload Your Photo:</strong> Drag and drop your image or upload it from your device.</li>
        <li>
          <strong>Convert to Anime:</strong> Our AI processes your photo and converts it into a vibrant anime-style
          image.
        </li>
        <li>
          <strong>Preview & Customize:</strong> Preview the anime version and make any necessary adjustments to achieve
          your desired look.
        </li>
        <li>
          <strong>Download:</strong> Download your anime-style image and share it on social media or use it as an
          avatar.
        </li>
      </ol>

      <p class="">
        Our Photo to Anime tool is optimized for both mobile and desktop devices, allowing you to transform your photos
        into anime art anytime, anywhere. No complex software is needed—just your creativity and our easy-to-use tool.
      </p>
    </div>
  </article>
</template>
