<template>
  <label class="flex flex-col space-y-2 items-center hover:opacity-80 transition cursor-pointer">
    <input
      class="peer hidden"
      type="radio"
      :value="value"
      :name="name"
      :checked="value === modelValue"
      @input="updateValue"
    />
    <div
      class="peer-checked:border-4 peer-checked:border-solid peer-checked:border-rose-500 peer-checked:rounded-lg peer-checked:opacity-80"
    >
      <slot />
    </div>
    <span class="peer-checked:font-bold">{{ label }}</span>
  </label>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    modelValue: string
    value: string
    name: string
    label?: string
  }>(),
  {}
)

const emit = defineEmits(['update:modelValue'])

const updateValue = (event: Event) => {
  emit('update:modelValue', (event.target as HTMLInputElement).value)
}
</script>

<style scoped lang="scss"></style>
