<template>
  <div v-if="ready" class="feature">
    <FeatureMeta :feature="featureStore.feature" />

    <div v-if="featureStore.images.length === 0">
      <FeatureInitSection :feature="featureStore.feature" :multiple="false" />
      <PageBlurFace />
    </div>

    <div v-else class="h-full">
      <img
        id="img"
        :src="getImageURL(featureStore.images[0])"
        :alt="featureStore.images[0].name"
        class="hidden"
        @load="handImageLoad"
      />

      <LayoutDefault>
        <div class="mx-auto px-8 relative">
          <div class="flex justify-center space-x-4">
            <button class="btn btn--sm btn--secondary max-w-max" :disabled="disabled" @click="detectFace">
              <i class="fa-solid fa-rotate"></i>
              <span>Reset (ESC)</span>
            </button>
            <button
              class="btn btn--sm btn--secondary max-w-max"
              :disabled="disabled"
              @click="addRect(canvas, 30, 100, 20, 120)"
            >
              <i class="fa-solid fa-plus"></i>
              <span>Add new mask (SPACE)</span>
            </button>
            <button
              class="btn btn--sm btn--secondary max-w-max"
              :disabled="disabled"
              @click="removeRect(canvas, canvas.getActiveObject())"
            >
              <i class="fa-solid fa-minus"></i>
              <span>Remove selected mask (DELETE)</span>
            </button>
          </div>
          <div class="mx-auto max-w-2xl flex mt-6">
            <canvas id="canvas" />
          </div>
        </div>

        <template #action>
          <button class="btn btn--lg btn--primary w-full" :disabled="disabled" @click="onSubmit">
            <i class="fa-solid fa-bolt-lightning"></i>
            <span v-if="disabled">Please wait...</span>
            <span v-else>{{ featureStore.feature.action }}</span>
          </button>
        </template>

        <template #aside>
          <form class="flex flex-col space-y-6 mt-8">
            <div v-for="val in ['low', 'medium', 'high']" :key="val" class="flex flex-col space-y-2">
              <div class="flex space-x-2 items-center">
                <input
                  :id="`detection_${val}`"
                  v-model="featureStore.options['detect-face'].detectionLevel"
                  type="radio"
                  class="radio"
                  :value="val"
                  :disabled="store.sending"
                  @change="detectFace"
                />
                <label :for="`detection_${val}`">{{ val.toUpperCase() }} detection</label>
              </div>
            </div>
          </form>

          <AppImageRatioInfo
            v-if="info.ratio"
            :real-width="imageDimension.width"
            :real-height="imageDimension.height"
            :client-width="info.clientWidth"
            :client-height="info.clientHeight"
            :ratio="info.ratio"
            class="mt-8"
          />
        </template>
      </LayoutDefault>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '&/composables/useFeatureSetup'
import PageBlurFace from '&/landing_pages/PageBlurFace.vue'
import FeatureMeta from '&/FeatureMeta.vue'
import { getImageURL, getCurrentFeatureSlug } from '%/utils'
import { onMounted, ref, watch, reactive, computed } from 'vue'
import { useNotification } from '@kyvg/vue3-notification'
import { fabric } from 'fabric'
import AppImageRatioInfo from '&/AppImageRatioInfo.vue'

const { ready, store, featureStore, route, router, LayoutDefault, FeatureInitSection } = useFeatureSetup()
const disabled = computed(() => store.sending)
const { notify } = useNotification()
const imageDimension = ref({})
const info = ref({})
let canvas = reactive({})

onMounted(async () => {
  if (route.query.keep_uploaded && featureStore.options['blur-face'].shapes) {
    setTimeout(async () => {
      await initCanvas()
      fillCanvas(featureStore.options['blur-face'].shapes)
    }, 500)
  }
})

function handImageLoad(event) {
  const width = event.target.naturalWidth
  const height = event.target.naturalHeight
  imageDimension.value.width = width
  imageDimension.value.height = height
}

watch(
  () => featureStore.images,
  () => {
    if (featureStore.images[0]) {
      setTimeout(() => {
        detectFace()
        initCanvas()
      }, 500)
    }
  },
  { deep: true }
)

watch(
  () => featureStore.results,
  () => {
    if (featureStore.results.length > 0 && featureStore.results[0].faces) {
      setTimeout(() => {
        removeAllShapes()
        fillCanvas(featureStore.results[0].faces)
      }, 500)
    }
  },
  { deep: true }
)

function initCanvas() {
  return new Promise(() => {
    const canvasElement = document.getElementById('canvas')
    let canvasWidth = null
    let canvasHeight = null
    canvas = new fabric.Canvas('canvas')

    fabric.Image.fromURL(getImageURL(featureStore.images[0]), function (img) {
      // Read the actual width of the canvas element
      const computedStyle = window.getComputedStyle(canvasElement)
      canvasWidth = parseInt(computedStyle.width)

      // Calculate the new height of the canvas based on the image's aspect ratio
      const aspectRatio = img.height / img.width
      canvasHeight = canvasWidth * aspectRatio

      // Set canvas dimensions dynamically
      canvas.setWidth(canvasWidth)
      canvas.setHeight(canvasHeight)

      // Set the image as the background of the canvas
      canvas.setBackgroundImage(
        img,
        function () {
          canvas.renderAll()

          setTimeout(() => {
            info.value.clientWidth = Math.round(canvasWidth)
            info.value.clientHeight = Math.round(canvasHeight)
            info.value.ratio = (imageDimension.value.width / info.value.clientWidth).toFixed(2).replace(/\.00$/, '')
          }, 500)
        },
        {
          originX: 'left',
          originY: 'top',
          scaleX: canvasWidth / img.width,
          scaleY: canvasHeight / img.height
        }
      )
    })

    // Setting active object on click
    canvas.on('mouse:down', function (options) {
      if (options.target) {
        canvas.setActiveObject(options.target)
      }
    })

    // Listening for delete key press
    document.addEventListener('keydown', function (e) {
      // Check if 'Delete' key is pressed
      if (['Backspace', 'Delete'].includes(e.key)) {
        removeRect(canvas, canvas.getActiveObject())
      } else if (e.code === 'Space') {
        addRect(canvas)
      } else if (e.code === 'Escape') {
        detectFace()
      }
    })
  })
}

function fillCanvas(faces) {
  setTimeout(() => {
    faces.forEach((face) => {
      const x1 = face.x1 / info.value.ratio
      const x2 = face.x2 / info.value.ratio
      const y1 = face.y1 / info.value.ratio
      const y2 = face.y2 / info.value.ratio
      addRect(canvas, x1, x2, y1, y2)
    })
  }, 1000)
}

function addRect(canvas, x1 = 30, x2 = 100, y1 = 20, y2 = 120) {
  const rect = new fabric.Rect({
    left: x1,
    top: y1,
    width: x2 - x1,
    height: y2 - y1,
    opacity: 0.5,
    fill: 'blue',
    cornerColor: 'blue',
    cornerSize: 8,
    transparentCorners: false,
    hasRotatingPoint: true
  })
  canvas.add(rect)
}

function removeRect(canvas, object) {
  if (object) {
    canvas.remove(object)
  }
}

function removeAllShapes() {
  const objects = canvas.getObjects()
  for (let i = objects.length - 1; i >= 0; i--) {
    // Check if the object is of a shape type
    if (
      objects[i].type === 'rect' ||
      objects[i].type === 'circle' ||
      objects[i].type === 'triangle' ||
      objects[i].type === 'polygon'
    ) {
      canvas.remove(objects[i])
    }
  }
  canvas.renderAll()
  featureStore.options['blur-face'].shapes = []
}

function getShapeCoordinates(canvas, ratio) {
  const objects = canvas.getObjects()
  return objects.map((obj) => {
    const scaleX = obj.scaleX || 1 // Default to 1 if scaleX is undefined
    const scaleY = obj.scaleY || 1 // Default to 1 if scaleY is undefined
    let width = obj.width * scaleX
    let height = obj.height * scaleY

    if (obj.type === 'circle') {
      // For circles, handle differently since width/height does not directly apply
      width = obj.radius * 2 * scaleX
      height = obj.radius * 2 * scaleY
    }

    let x1 = obj.left
    let y1 = obj.top
    let x2 = obj.left + width
    let y2 = obj.top + height

    // Adjust for origin if not default (top-left)
    if (obj.originX === 'center') {
      x1 = obj.left - width / 2
      x2 = obj.left + width / 2
    }
    if (obj.originY === 'center') {
      y1 = obj.top - height / 2
      y2 = obj.top + height / 2
    }

    return { type: obj.type, x1: x1 * ratio, x2: x2 * ratio, y1: y1 * ratio, y2: y2 * ratio }
  })
}

function detectFace() {
  store.sending = true
  featureStore
    .execute('detect-face')
    .then(() => {})
    .catch((err) => {
      console.error(err)
    })
    .finally(() => (store.sending = false))
}

function onSubmit() {
  store.sending = true
  featureStore.options['blur-face'].shapes = getShapeCoordinates(canvas, info.value.ratio)
  featureStore.progressModal.show()
  featureStore
    .execute(getCurrentFeatureSlug())
    .then(() => {
      router.push({ name: 'BlurFaceResult' })
    })
    .catch((err) => {
      console.error(err)
      notify({
        type: 'error',
        title: 'Error',
        text: err.response?.data?.error
      })
    })
    .finally(() => {
      store.sending = false
      featureStore.progressModal.hide()
    })
}
</script>

<style scoped lang="scss">
:deep(.canvas-container) {
  width: 100%;
  flex-grow: 1;
  canvas {
    width: 100% !important;
  }
}
</style>
