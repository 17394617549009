<template>
  <article class="feature-page">
    <div class="max-w-4xl mx-auto px-4 py-10">
      <h2 class="text-2xl lg:text-3xl font-bold text-center mb-4">
        piceditor.io Resize Image: Perfect Your Photos Instantly
      </h2>
      <p class="text-xl text-center mb-6">Quickly Adjust Your Photo Dimensions</p>
      <p class="mb-4">
        Whether you’re optimizing for web or preparing images for print, our Resize Image tool allows you to precisely
        alter the dimensions of your photos to meet any specification. With easy-to-use sliders and instant previews,
        you can effortlessly scale your images up or down without sacrificing quality.
      </p>

      <h3 class="text-2xl font-bold mt-12 mb-6">Why Use Resize Image?</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 feature-page__specific">
        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/platform.png" alt="works anywhere" />
          <strong>Works Anywhere</strong>
          <p>Easily adjust images for perfect display on platforms like Instagram, Facebook, and print media.</p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/speed.png" alt="fast speed" />
          <strong>Fast Speed</strong>
          <p>Resize images quickly and download them instantly, ready for any use.</p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/quality.png" alt="perfect quality" />
          <strong>Perfect Quality</strong>
          <p>Maintain flawless quality, regardless of image size adjustments, with our advanced resizing algorithm.</p>
        </div>
      </div>

      <h3 class="text-2xl font-bold mt-12 mb-6">How It Works</h3>
      <ol class="list-decimal pl-5 mb-6">
        <li><strong>Upload Your Image:</strong> Drag and drop your images or upload from your device.</li>
        <li><strong>Set Dimensions:</strong> Choose from preset sizes or enter custom dimensions.</li>
        <li>
          <strong>Resize & Preview:</strong> Instantly see a preview of the final result and make adjustments if
          necessary.
        </li>
        <li><strong>Download:</strong> Once you’re satisfied, download the resized images.</li>
      </ol>

      <p class="">
        Our tool is designed to work seamlessly on desktops, tablets, and mobile phones, giving you the flexibility to
        resize images no matter where you are or what device you’re using.
      </p>
    </div>
  </article>
</template>
