<template>
  <div v-if="ready" class="feature">
    <FeatureMeta :feature="featureStore.feature" />

    <div v-if="featureStore.images.length === 0">
      <FeatureInitSection :feature="featureStore.feature" :multiple="false" />
      <PageColorizeBlackAndWhite />
    </div>

    <div v-else class="h-full">
      <LayoutDefault @finished="onFinished">
        <template #aside>
          <div class="flex flex-col space-y-6 mt-8">
            <div class="flex flex-col space-y-2">
              <label for="version" class="input-label">Version</label>
              <div class="">
                <select id="version" v-model="featureStore.options[slug].version" class="input-field w-full">
                  <option v-for="version in [2, 1]" :key="version">{{ version }}</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </LayoutDefault>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '&/composables/useFeatureSetup'
import PageColorizeBlackAndWhite from '&/landing_pages/PageColorizeBlackAndWhite.vue'
import FeatureMeta from '&/FeatureMeta.vue'

const { ready, router, featureStore, slug, LayoutDefault, FeatureInitSection } = useFeatureSetup()

function onFinished() {
  router.push({ name: 'ColorizeBlackAndWhiteResult' })
}
</script>
