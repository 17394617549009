<template>
  <article class="feature-page">
    <div class="max-w-4xl mx-auto px-4 py-10">
      <h2 class="text-2xl lg:text-3xl font-bold text-center mb-4">
        piceditor.io Upscale & Restore Image: Revitalize and Enhance Your Photos
      </h2>
      <p class="text-xl text-center mb-6">Bring New Life to Your Old and Low-Resolution Images</p>
      <p class="mb-4">
        Transform your old, faded, or low-resolution photos with our Upscale & Restore tool. Perfect for reviving
        cherished memories, this feature enhances image resolution and restores details, giving your photos a fresh and
        vibrant look. Our advanced AI technology ensures that every detail is preserved and enhanced, delivering
        stunning results.
      </p>

      <h3 class="text-2xl font-bold mt-12 mb-6">Why Use Upscale & Restore?</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 feature-page__specific">
        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/ai.png" alt="AI technology" />
          <strong>Advanced AI Technology</strong>
          <p>
            Leverage cutting-edge AI to enhance resolution and restore lost details, giving your images new clarity and
            life.
          </p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/recovery.png" alt="image restoration" />
          <strong>Image Restoration</strong>
          <p>Restore faded colors, sharpen details, and fix imperfections to bring your old photos back to life.</p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/resolution.png" alt="resolution enhancement" />
          <strong>Resolution Enhancement</strong>
          <p>Upscale your images without losing quality, ensuring they look sharp and clear even at larger sizes.</p>
        </div>
      </div>

      <h3 class="text-2xl font-bold mt-12 mb-6">How It Works</h3>
      <ol class="list-decimal pl-5 mb-6">
        <li><strong>Upload Your Image:</strong> Drag and drop your image or upload it from your device.</li>
        <li>
          <strong>AI Processing:</strong> Our AI will analyze your image and apply the necessary enhancements to restore
          and upscale it.
        </li>
        <li>
          <strong>Preview & Adjust:</strong> Preview the results and make any fine-tuning adjustments to ensure the
          image meets your expectations.
        </li>
        <li>
          <strong>Download:</strong> Download your newly restored and upscaled image, ready for printing, sharing, or
          archiving.
        </li>
      </ol>

      <p class="">
        Designed to work seamlessly across all devices, our Upscale & Restore tool allows you to enhance and revitalize
        your images anywhere, anytime. Perfect for both personal and professional use, this tool brings out the best in
        every photo.
      </p>
    </div>
  </article>
</template>
