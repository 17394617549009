<template>
  <div class="profile h-full">
    <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" class="inline-flex items-center fixed top-20 left-4 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 z-30">
      <span class="sr-only">Open sidebar</span>
      <svg class="w-10 h-10" aria-hidden="false" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
      </svg>
    </button>

    <aside id="default-sidebar" class="fixed top-16 sm:top-20 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
      <div class="h-full px-4 py-8 overflow-y-auto bg-white">
        <div class="flex space-x-2 items-center">
          <img :src="sessionStore.user.image" :alt="sessionStore.user.name" class="rounded-full w-16"/>
          <div>
            <div class="break-all font-bold text-gray-700">{{ sessionStore.user.name }}</div>
            <div class="mt-2 font-bold text-primary">Free user</div>
          </div>
        </div>

        <div class="my-8">
          <hr class="border-solid border-gray-300">
        </div>

        <ul class="space-y-2 font-medium">
          <li>
            <router-link
              :to="{ name: 'MyAccount' }"
              class="link-item"
              :class="{'link-item--active': route.name === 'MyAccount' }"
            >
              <i class="fa-solid fa-user w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"></i>
              <span>My account</span>
            </router-link>
          </li>

          <li>
            <hr class="border-solid border-gray-300">
          </li>

          <li>
            <button
              class="link-item"
              @click="onSignOut"
            >
              <i class="fa-solid fa-right-from-bracket"/>
              <span>Sign out</span>
            </button>
          </li>
        </ul>
      </div>
    </aside>

    <main class="p-4 sm:ml-64 h-full bg-zinc-100">
      <router-view/>
    </main>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import { useAuth } from '&/composables/useAuth'
import { useFlowbite } from '&/composables/useFlowbite'

useFlowbite(['drawers'])

const route = useRoute()
const { sessionStore } = useAuth()

const onSignOut = function () {
  sessionStore.signOut().then(() => {
    window.location.href = '/'
  })
}
</script>
