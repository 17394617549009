import { defineStore } from "pinia"
import apiCall from "%/plugins/axios";

export const useSessionStore = defineStore('session', {
    state: () => {
        return {
            access_token: null,
            client: null,
            uid: null,
            user: null
        }
    },

    actions: {
        signUp(payload) {
            return new Promise((resolve, reject) => {
                apiCall.post('/auth', payload)
                    .then(res => {
                        if (res.status === 200) {
                            resolve()
                        } else {
                            reject(res)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        signIn(payload) {
            return new Promise((resolve, reject) => {
                apiCall.post('/auth/sign_in', payload)
                    .then(res => {
                        if (res.status === 200) {
                            this.saveToken(
                                {
                                    access_token: res.headers["access-token"],
                                    client: res.headers["client"],
                                    uid: res.headers["uid"]
                                }
                            )
                            resolve()
                        } else {
                            reject(res)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        signInWithToken() {
            const config = { headers: this.loadToken }
            return new Promise((resolve, reject) => {
                apiCall.get('/signed_in_user', config)
                    .then(res => {
                        this.user = res.data
                        this.access_token = res.headers['access-token']
                        this.client = res.headers['client']
                        this.uid = res.headers['uid']
                        this.saveHeaders(
                            {
                                access_token: res.headers['access-token'],
                                client: res.headers['client'],
                                uid: res.headers['uid']
                            }
                        )
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        signOut() {
            const data = this.loadToken
            return new Promise((resolve, reject) => {
                apiCall.delete('/auth/sign_out', data)
                    .then(() => {
                        this.user = null
                        this.access_token = null
                        this.client = null
                        this.uid = null
                        this.deleteToken()
                        this.deleteHeaders()
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        reSendConfirmationEmail(payload) {
            return new Promise((resolve, reject) => {
                apiCall.post('/auth/confirmation', payload)
                    .then(res => {
                        if (res.status === 200) {
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        sendResetPasswordInstructions(payload) {
            return new Promise((resolve, reject) => {
                apiCall.post('/auth/password', payload)
                    .then(res => {
                        if (res.status === 200) {
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        changePassword(payload, config) {
            return new Promise((resolve, reject) => {
                apiCall.put('/auth/password', payload, config)
                    .then(res => {
                        if (res.status === 200) {
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        updateProfile(payload, config) {
            return new Promise((resolve, reject) => {
                apiCall.put('/auth', payload, config)
                    .then(res => {
                        if (res.status === 200) {
                            this.user.name = res.data.data.name
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        // Set token to local storage
        saveToken(payload) {
            localStorage.setItem("access-token", payload.access_token)
            localStorage.setItem("client", payload.client)
            localStorage.setItem("uid", payload.uid)
        },

        deleteToken() {
            localStorage.removeItem('access-token')
            localStorage.removeItem('client')
            localStorage.removeItem('uid')
        },

        // Save headers for API call
        saveHeaders(payload) {
            apiCall.defaults.headers.common['access-token'] = payload.access_token
            apiCall.defaults.headers.common['client'] = payload.client
            apiCall.defaults.headers.common['uid'] = payload.uid
        },

        deleteHeaders() {
            apiCall.defaults.headers.common['access-token'] = null
            apiCall.defaults.headers.common['client'] = null
            apiCall.defaults.headers.common['uid'] = null
        }
    },

    getters: {
        isSignedIn: (state) => !!state.user,

        // Get token from local storage
        loadToken: () => {
            return {
                'access-token': localStorage.getItem("access-token"),
                'client': localStorage.getItem("client"),
                'uid': localStorage.getItem("uid"),
            }
        }
    }
})
