<template>
  <article class="feature-page">
    <div class="max-w-4xl mx-auto px-4 py-10">
      <h2 class="text-2xl lg:text-3xl font-bold text-center mb-4">
        piceditor.io Convert Image: Effortlessly Change Image Formats
      </h2>
      <p class="text-xl text-center mb-6">Seamlessly Transform Your Photos Into Different Formats</p>
      <p class="mb-4">
        Need to switch your image to a different format for web optimization, social media, or archival purposes? Our
        Convert Image tool allows you to quickly change your photo's file type to a variety of formats, including JPG,
        PNG, GIF, and more. Utilize our straightforward interface to convert your images without losing quality,
        ensuring they meet your specific needs and platform requirements.
      </p>

      <h3 class="text-2xl font-bold mt-12 mb-6">Why Use Convert Image?</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 feature-page__specific">
        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/compatibility.png" alt="compatibility" />
          <strong>Broad Compatibility</strong>
          <p>Convert images to widely accepted formats ensuring compatibility across all platforms and devices.</p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/efficiency.png" alt="efficient conversion" />
          <strong>Efficient Conversion</strong>
          <p>Experience quick and efficient image conversions with our optimized processing technology.</p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/integrity.png" alt="maintain quality" />
          <strong>Maintain Quality</strong>
          <p>Ensure your images maintain their original quality and detail through the conversion process.</p>
        </div>
      </div>

      <h3 class="text-2xl font-bold mt-12 mb-6">How It Works</h3>
      <ol class="list-decimal pl-5 mb-6">
        <li>
          <strong>Upload Your Image:</strong> Simply upload your image from your device or drag and drop it into the
          converter.
        </li>
        <li><strong>Select Format:</strong> Choose the format you need from our extensive list of options.</li>
        <li>
          <strong>Convert & Preview:</strong> Quickly convert your image and preview the result to ensure it meets your
          expectations.
        </li>
        <li><strong>Download:</strong> Download the converted image, now ready for any application or use.</li>
      </ol>

      <p class="">
        Our Convert Image tool is designed to operate flawlessly across desktops, tablets, and smartphones, enabling you
        to manage your image files efficiently, no matter your location or device.
      </p>
    </div>
  </article>
</template>
