<template>
  <div v-if="ready" class="feature">
    <FeatureMeta :feature="featureStore.feature" />

    <div v-if="featureStore.images.length === 0">
      <FeatureInitSection :feature="featureStore.feature" />
      <PageRotateImage />
    </div>

    <div v-else class="h-full">
      <LayoutDefault :max-upload="maxUpload" @finished="onFinished">
        <template #aside>
          <div class="flex flex-col space-y-6 mt-8">
            <div class="flex space-x-8">
              <label for="rotateAngle" class="input-label">Rotate angle</label>
              <div class="flex flex-col space-y-2">
                <div
                  v-for="(angle, index) in [
                    ['-90° (left)', -90],
                    ['0° (normal)', 0],
                    ['90° (right)', 90],
                    ['180°', 180]
                  ]"
                  :key="index"
                >
                  <input
                    :id="`rotate_${index}`"
                    v-model="featureStore.options[slug].rotateAngle"
                    class="radio"
                    :value="angle[1]"
                    type="radio"
                  />
                  <label class="input-label" :for="`rotate_${index}`">{{ angle[0] }}</label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </LayoutDefault>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '&/composables/useFeatureSetup'
import PageRotateImage from '&/landing_pages/PageRotateImage.vue'
import FeatureMeta from '&/FeatureMeta.vue'

const { ready, router, featureStore, slug, LayoutDefault, FeatureInitSection } = useFeatureSetup()
const maxUpload = 10

function onFinished() {
  router.push({ name: 'RotateImageResult' })
}
</script>
