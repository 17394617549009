<template>
  <div class="grid grid-cols-2 gap-8 md:gap-0 h-full">
    <main class="col-span-2 md:col-span-1">
      <div class="py-8 mx-auto px-4 lg:px-12 h-full flex flex-col justify-center">
        <div>
          <div class="flex flex-col space-y-8 items-center justify-center">
            <AppLogo/>
            <h1 class="gradient-title">Sign up</h1>
          </div>

          <div class="mt-12 max-w-[400px] mx-auto">
            <form class="default-form" @submit.prevent="onSignUp">
              <div>
                <AppInputField v-model="form.name" label="Name" placeholder="Name" :maxlength="30" autofocus>
                  <i class="fa-solid fa-user"></i>
                </AppInputField>
              </div>

              <div>
                <AppInputField v-model="form.email" label="Email" placeholder="Email" type="email">
                  <i class="fa-solid fa-envelope"></i>
                </AppInputField>
              </div>

              <div>
                <AppInputField v-model="form.password" label="Password" placeholder="Password (min 6 characters" type="password">
                  <i class="fa-solid fa-key"></i>
                </AppInputField>
              </div>

              <div class="flex justify-center">
                <button :disabled="store.sending" type="submit" class="btn btn--lg btn--primary">
                  <i class="fa-solid fa-user-plus"></i>
                  <span>Sign up</span>
                </button>
              </div>

              <div class="flex flex-col space-y-4 mt-8">
                <div>
                  <span class="mr-1">Already have an account?</span>
                  <router-link
                    :to="{ name: 'SignIn' }"
                    class="text-primary font-bold"
                  >Sign in</router-link
                  >
                </div>
              </div>
            </form>

            <hr class="my-6 border border-solid border-gray-300" />
            <div class="">
              <OAuth/>
            </div>
          </div>

        </div>
      </div>
    </main>

    <aside class="col-span-2 md:col-span-1 bg-teal-50">
      <div class="py-8 mx-auto px-4 lg:px-12 h-full flex flex-col justify-center max-w-[640px]">
        <div>
          <img src="@/images/pages/auth_top.png" alt="Register to piceditor.io" class="w-2/3 mx-auto"/>
          <h1 class="hidden">{{ welcomeTexts[0] }}</h1>
          <div
            class="mt-12 font-extrabold text-transparent text-3xl bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600"
          >
            {{ welcomeText }}
          </div>
        </div>
      </div>
    </aside>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useAuth } from '&/composables/useAuth'

const { router, store, sessionStore, pushNotify, pushError, OAuth, AppInputField, AppLogo } = useAuth()

const welcomeTexts = [
  'Create Your Account and Start Editing Photos Online',
  'Unlock the full potential of your images - Join piceditor.io today!',
  'Ready to elevate your photo editing skills? Sign up for piceditor.io now!',
  'Start your journey to amazing photo edits - Sign up at piceditor.io!',
  'Explore advanced editing features exclusively on piceditor.io - Join now!',
  'Become a member of piceditor.io and transform your images effortlessly!',
  'Join our community of photo editing enthusiasts - Sign up and get started!',
  'Create stunning visuals with our easy-to-use tools - Sign up today!',
  'Sign up for piceditor.io and access premium editing features instantly!',
  'Step into the future of image editing - Create your account at piceditor.io now!',
  'Join piceditor.io and start creating professional-quality images today!'
]
const welcomeText =
  welcomeTexts[Math.floor(Math.random() * welcomeTexts.length)]

const form = reactive({
  name: '',
  email: '',
  password: '',
})

const onSignUp = function() {
  store.sending = true
  sessionStore
    .signUp(form)
    .then(() => {
      router.push({ name: 'SignIn' })
      let msg = `A confirmation email was sent to your account at '${form.email}'. You must follow the instructions in the email before your account can be activated`
      pushNotify(msg)
    })
    .catch(err => {
      store.sending = false
      let error = err?.response?.data?.errors?.full_messages?.join('\n') || err?.message
      pushError(error)
    })
    .finally(() => {
      store.sending = false
    })
}
</script>
