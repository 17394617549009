<template>
  <div v-if="ready" class="feature">
    <FeatureMeta :feature="featureStore.feature" />

    <div v-if="featureStore.images.length === 0">
      <FeatureInitSection :feature="featureStore.feature" />
      <PageCompressImage />
    </div>

    <div v-else class="h-full">
      <LayoutDefault :max-upload="maxUpload" @finished="onFinished"> </LayoutDefault>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFeatureSetup } from '&/composables/useFeatureSetup'
import PageCompressImage from '&/landing_pages/PageCompressImage.vue'
import FeatureMeta from '&/FeatureMeta.vue'

const maxUpload = 10

const { ready, router, featureStore, LayoutDefault, FeatureInitSection } = useFeatureSetup()

function onFinished() {
  router.push({ name: 'CompressImageResult' })
}
</script>
