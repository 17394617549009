<template>
  <article class="feature-page">
    <div class="max-w-4xl mx-auto px-4 py-10">
      <h2 class="text-2xl lg:text-3xl font-bold text-center mb-4">
        piceditor.io Blur Background: Instantly Focus Attention on Your Subject
      </h2>
      <p class="text-xl text-center mb-6">Effortlessly Blur Backgrounds to Make Your Subjects Stand Out</p>
      <p class="mb-4">
        Transform your photos by blurring the background, instantly drawing attention to the subject of your image. Ideal for portraits, product photography, and social media content, our Blur Background tool lets you create a professional look with just a few clicks. Preview changes in real-time, ensuring you achieve the perfect effect every time.
      </p>

      <h3 class="text-2xl font-bold mt-12 mb-6">Why Use Our Blur Background Tool?</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 feature-page__specific">
        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/focus.png" alt="focus attention" />
          <strong>Highlight Your Subject</strong>
          <p>
            Blur the background to keep all eyes on your subject, enhancing the overall impact of your photo.
          </p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/customize.png" alt="customize blur" />
          <strong>Professional Background Blur</strong>
          <p>
            Easily blur backgrounds to give your photos a polished, professional look.
          </p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/user-friendly.png" alt="user friendly" />
          <strong>Easy to Use</strong>
          <p>
            Our intuitive interface makes it simple for anyone to create stunning blurred backgrounds, whether you're a beginner or a pro.
          </p>
        </div>
      </div>

      <h3 class="text-2xl font-bold mt-12 mb-6">How It Works</h3>
      <ol class="list-decimal pl-5 mb-6">
        <li><strong>Upload Your Image:</strong> Drag and drop your photo or upload it from your device.</li>
        <li>
          <strong>Apply Blur:</strong> Adjust the blur intensity using our intuitive sliders and preview the effect instantly.
        </li>
        <li>
          <strong>Preview & Adjust:</strong> Make any necessary tweaks to ensure your subject stands out perfectly.
        </li>
        <li>
          <strong>Download:</strong> Download your edited photo with a beautifully blurred background, ready to share or use wherever you need.
        </li>
      </ol>

      <p class="">
        Optimized for all devices, our Blur Background tool allows you to create professional-quality images quickly and effortlessly, wherever you are.
      </p>
    </div>
  </article>
</template>
