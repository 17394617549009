<template>
  <footer class="p-6 bg-slate-100 border-t-2 border-solid border-teal-700 z-50">
    <div class="flex justify-between mx-auto max-w-screen-xl">
      <div>&copy; PicEditor 2024</div>

      <div>
        <ul>
          <li>
            <router-link :to="{ name: 'PageFAQ' }" class="link">FAQ</router-link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
