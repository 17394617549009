<template>
  <notifications position="top center">
    <template #body="props">
      <div :class="`notification notification--${props.item.type}`">
        <p class="title">
          {{ props.item.title }}
        </p>
        <div v-html="props.item.text?.replace(/\n/g, '<br>')" />
      </div>
    </template>
  </notifications>

  <div v-if="store.ready" class="flex flex-col h-full">
    <TheHeader />
    <TheContainer class="flex-grow" />
    <TheFooter />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useStore } from '%/stores'
import { useFeatureStore } from '%/stores/feature'
import { useSessionStore } from '%/stores/session'
import TheHeader from '&/TheHeader.vue'
import TheFooter from '&/TheFooter.vue'
import TheContainer from '&/TheContainer.vue'

const store = useStore()
const featureStore = useFeatureStore()
const sessionStore = useSessionStore()
const access_token = localStorage.getItem('access-token')

onMounted(async () => {
  await store.fetchSystemConfigs()
  await featureStore.fetchFeatures()

  if (access_token) {
    sessionStore.signInWithToken().finally(() => {
      store.ready = true
    })
  } else {
    store.ready = true
  }
})
</script>
