<template>
  <teleport v-if="store.systemConfigs.faq_meta_title" to="head">
    <title>{{ store.systemConfigs.faq_meta_title }}</title>
    <meta name="description" :content="`${store.systemConfigs.faq_meta_description}`" />
  </teleport>

  <main class="mx-auto max-w-screen-xl px-4 py-12">
    <div class="flex justify-center mt-8">
      <h1 class="gradient-title">Frequently Asked Questions</h1>
    </div>

    <section class="mt-12">
      <div
        id="accordion-collapse"
        data-accordion="open"
        class="mx-auto max-w-4xl shadow-lg"
        data-active-classes="bg-blue-100"
      >
        <template v-for="(faq, index) in faqs" :key="index">
          <h2 :id="`accordion-collapse-heading-${index + 1}`" class="">
            <button
              type="button"
              class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
              :data-accordion-target="`#accordion-collapse-body-${index + 1}`"
              aria-expanded="false"
              :aria-controls="`accordion-collapse-body-${index + 1}`"
            >
              <span class="font-bold text-left">{{ faq[0] }}</span>
              <svg
                data-accordion-icon
                class="w-3 h-3 rotate-180 shrink-0"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5 5 1 1 5"
                />
              </svg>
            </button>
          </h2>
          <div
            :id="`accordion-collapse-body-${index + 1}`"
            class="hidden"
            :aria-labelledby="`accordion-collapse-heading-${index + 1}`"
          >
            <div class="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
              <p class="mb-2 text-gray-500 dark:text-gray-400">{{ faq[1] }}</p>
            </div>
          </div>
        </template>
      </div>
    </section>
  </main>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useStore } from '%/stores'

import { useFlowbite } from '&/composables/useFlowbite'

const store = useStore()
const faqs = ref([
  [
    'What is piceditor.io?',
    "piceditor.io is an advanced online photo editing platform designed to streamline the process of enhancing and modifying your images. Whether you're a professional photographer, a digital marketer, or simply someone looking to refine personal photos, piceditor.io offers a suite of powerful tools that cater to your needs. Our platform includes features like real-time shadow generation, face blurring, image resizing, background removal, and more. All accessible directly through your web browser."
  ],
  [
    'Do my images remain on your servers?',
    'Your images are solely yours. We temporarily store your files on our servers to facilitate your download of the edited images. After this, they are permanently deleted from our systems. We do not access, view, or copy your files under any circumstances.'
  ],
  [
    'Can I use your services for my business?',
    'Absolutely. We prioritize your privacy with all uploads being encrypted using the HTTPS/SSL protocol. This security measure ensures compliance with the strictest corporate data protection policies. Simply put, we handle your data with the utmost care, meeting high privacy standards.'
  ],
  [
    'How can I select images from my computer?',
    'You can upload images either by dragging and dropping them directly from your desktop to our site or by selecting them individually through a file selector. For multiple selections, if the files are consecutively listed, click on the first image, then hold down the "Shift" key and click on the last image you wish to select.'
  ],
  [
    "Why doesn't the page work correctly?",
    'If you encounter issues uploading files or if some functions on the page are not working as expected, please consider disabling any web extensions like AdBlock. These extensions can interfere with our tools’ functionality. We ensure that any ads on our site are minimally invasive. If issues persist, try using your browser in Incognito mode.'
  ]
])

useFlowbite(['accordions'])
</script>
