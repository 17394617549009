<template>
  <teleport v-if="store.systemConfigs.home_meta_title" to="head">
    <title>{{ store.systemConfigs.home_meta_title }}</title>
    <meta name="description" :content="`${store.systemConfigs.home_meta_description}`" />
  </teleport>

  <main>
    <section class="main bg-stone-100 h-full p-4 py-12">
      <div class="text-center">
        <h1 class="gradient-title">Effortless Image Editing and Conversion</h1>
      </div>

      <div class="text-center mt-4">
        <h2 class="text-lg lg:text-2xl">Edit Your Photos Online in Just a Few Clicks</h2>
      </div>

      <div class="mt-8 mx-auto max-w-screen-xl">
        <div class="grid grid-cols-12 gap-4">
          <FeatureItem
            v-for="feature in featureStore.features"
            :key="feature.id"
            :feature="feature"
            class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3"
          />
        </div>
      </div>
    </section>

    <section class="site-specific mx-auto max-w-screen-xl px-2">
      <div class="text-center p-12">
        <h2 class="">Photo Editing Made Simple and Powerful</h2>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-16 px-8">
        <div>
          <h3 class="">Optimize Images for the Web</h3>
          <p class="">
            Effortlessly resize, compress, and transform your photos with pixel-perfect precision. Perfectly tailor your
            images for web, social media, and e-commerce platforms. Get instant results with our user-friendly tools
            that ensure your visuals stand out online.
          </p>
        </div>

        <div>
          <h3>Batch Photo Processing</h3>
          <p>
            Streamline your workflow with batch processing capabilities. PicEditor supports a wide range of formats
            including PNG, JPG, GIF, WEBP, and HEIC. Easily convert and process multiple files simultaneously.
          </p>
        </div>

        <div>
          <h3>Trusted Online Image Editor</h3>
          <p>
            PicEditor is the go-to solution for image editing online. Embrace the simplicity and efficiency of our
            platform, trusted and loved by users worldwide. Secure, reliable, and easy to use — enhance your images and
            documents directly from your browser with total peace of mind.
          </p>
        </div>
      </div>
    </section>

    <section class="site-specific bg-emerald-50 mt-12">
      <div class="site-specific mx-auto max-w-screen-xl px-2">
        <div class="text-center pt-12 pb-16">
          <img src="@/images/icons/secure.png" alt="Security and Reliability" class="mx-auto w-24 mb-6" />
          <h2 class="">Security and Reliability</h2>
          <p>Committed to your privacy and security, ensuring a secure connection with HTTPS for all operations.</p>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup lang="ts">
import { useStore } from '%/stores'
import { useFeatureStore } from '%/stores/feature'
import FeatureItem from '&/FeatureItem.vue'

const store = useStore()
const featureStore = useFeatureStore()
</script>

<style scoped lang="scss">
.main {
  background-image: url('@/images/system/background.jpg');
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.5);
  background-blend-mode: lighten;
}

.site-specific {
  h2 {
    font-weight: bolder;
    font-size: 1.75rem;
    color: #3f3f46;
  }
  h3 {
    font-weight: bolder;
    font-size: 1.2rem;
    color: #334155;
  }
  p {
    color: #475569;
    margin-top: 0.75rem;
  }
}
</style>
