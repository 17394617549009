<template>
  <article class="feature-page">
    <div class="max-w-4xl mx-auto px-4 py-10">
      <h2 class="text-2xl lg:text-3xl font-bold text-center mb-4">
        piceditor.io Remove Background: Effortlessly Clear Backgrounds from Your Images
      </h2>
      <p class="text-xl text-center mb-6">Seamlessly Remove Backgrounds with Precision</p>
      <p class="mb-4">
        Perfect for creating professional-looking photos, our Remove Background tool strips away the clutter, leaving
        only the subject in focus. Utilize our tool to enhance product images, professional portraits, or any photos
        requiring a clean look. Our AI-driven process ensures high accuracy and edge definition.
      </p>

      <h3 class="text-2xl font-bold mt-12 mb-6">Why Use Remove Background?</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 feature-page__specific">
        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/ai.png" alt="AI technology" />
          <strong>Powered by AI</strong>
          <p>Leverage advanced AI technology for flawless background removal that enhances image focus and quality.</p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/precision.png" alt="precision" />
          <strong>High Precision</strong>
          <p>Accurately detect and erase complex backgrounds, even around challenging edges and fine details.</p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/efficiency.png" alt="efficiency" />
          <strong>Quick and Efficient</strong>
          <p>Streamline your editing workflow with fast processing that returns results within seconds.</p>
        </div>
      </div>

      <h3 class="text-2xl font-bold mt-12 mb-6">How It Works</h3>
      <ol class="list-decimal pl-5 mb-6">
        <li><strong>Upload Your Image:</strong> Drag and drop your image or upload it from your device.</li>
        <li>
          <strong>Remove Background:</strong> Our AI will automatically process your image and remove the background.
        </li>
        <li>
          <strong>Preview & Adjust:</strong> Preview the result and make any necessary adjustments to ensure the perfect
          output.
        </li>
        <li>
          <strong>Download:</strong> Download your image with a transparent or a solid color background as needed.
        </li>
      </ol>

      <p class="">
        Our tool is optimized for both mobile and desktop devices, ensuring you can remove backgrounds from your images
        anywhere, at any time, without the need for complex software.
      </p>
    </div>
  </article>
</template>
