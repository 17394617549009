import { onMounted } from "vue";
import { useStore } from "%/stores";

import {
    initAccordions,
    initCarousels,
    initCollapses,
    initDials,
    initDismisses,
    initDrawers,
    initDropdowns,
    initModals,
    initPopovers,
    initTabs,
    initTooltips
} from 'flowbite';

export function useFlowbite(components) {
    const store = useStore();

    onMounted(() => {
        if (store.ready) {
            components.forEach(component => {
                switch (component) {
                    case 'accordions': initAccordions(); break;
                    case 'carousels': initCarousels(); break;
                    case 'collapses': initCollapses(); break;
                    case 'dropdowns': initDropdowns(); break;
                    case 'dials': initDials(); break;
                    case 'dismisses': initDismisses(); break;
                    case 'drawers': initDrawers(); break;
                    case 'modals': initModals(); break;
                    case 'popovers': initPopovers(); break;
                    case 'tabs': initTabs(); break;
                    case 'tooltips': initTooltips(); break;
                    default:
                        console.error(`Component "${component}" is not recognized or not supported.`);
                }
            });
        }
    });
}
