<template>
  <div class="feature-result">
    <FeatureResultSection
      id="result"
      :slug="featureStore.feature.slug"
      title="Your images have been removed background!"
    />

    <div class="overflow-x-scroll mt-8 p-2">
      <div
        v-for="(result, index) in featureStore.results"
        :key="result.image_url"
        class="max-w-2xl mx-auto flex flex-col space-y-4 items-center justify-center"
      >
        <AppCopyResultImage :url="result.image_url" />
        <AppComparisonSlider :first="getImageURL(featureStore.images[index])" :second="result.image_url" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFeatureStore } from '%/stores/feature'
import FeatureResultSection from '&/FeatureResultSection.vue'
import AppCopyResultImage from '&/AppCopyResultImage.vue'
import AppComparisonSlider from '&/AppComparisonSlider.vue'
import { getImageURL } from '%/utils'

const featureStore = useFeatureStore()
</script>
