<template>
  <article class="feature-page">
    <div class="max-w-4xl mx-auto px-4 py-10">
      <h2 class="text-2xl lg:text-3xl font-bold text-center mb-4">
        piceditor.io Compress Image: Optimize Your Photos for Faster Loading
      </h2>
      <p class="text-xl text-center mb-6">Quickly Reduce File Sizes Without Losing Quality</p>
      <p class="mb-4">
        Ideal for enhancing online content, our Compress Image tool reduces image file sizes making web pages load
        faster while preserving the high quality of your photos. Use our tool to streamline your website, speed up page
        loading, and improve overall site performance. Our efficient algorithm ensures no visible loss of quality.
      </p>

      <h3 class="text-2xl font-bold mt-12 mb-6">Why Use Compress Image?</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 feature-page__specific">
        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/efficiency.png" alt="Efficient Compression" />
          <strong>Efficient Compression</strong>
          <p>Significantly reduce file sizes with our advanced compression techniques that maintain image integrity.</p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/speed.png" alt="Fast Processing" />
          <strong>Fast Processing</strong>
          <p>Enjoy quick compression that allows your images to be processed and ready to download in seconds.</p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/quality.png" alt="No Quality Loss" />
          <strong>Preserves Quality</strong>
          <p>Maintain the original visual quality of your images even after significant size reduction.</p>
        </div>
      </div>

      <h3 class="text-2xl font-bold mt-12 mb-6">How It Works</h3>
      <ol class="list-decimal pl-5 mb-6">
        <li><strong>Upload Your Image:</strong> Drag and drop your image or upload it from your device.</li>
        <li><strong>Compress Image:</strong> Our tool automatically reduces the file size while preserving quality.</li>
        <li>
          <strong>Preview & Adjust:</strong> Preview the compressed image and adjust compression levels if necessary.
        </li>
        <li>
          <strong>Download:</strong> Download the optimized image, ready for use on your site or for any other purpose.
        </li>
      </ol>

      <p class="">
        Optimized for usability across both mobile and desktop devices, our tool ensures you can compress images
        effortlessly, anywhere, and anytime without the need for additional software installations.
      </p>
    </div>
  </article>
</template>
