<template>
  <article class="feature-page">
    <div class="max-w-4xl mx-auto px-4 py-10">
      <h2 class="text-2xl lg:text-3xl font-bold text-center mb-4">
        piceditor.io Rotate Image: Adjust Your Photos’ Orientation Instantly
      </h2>
      <p class="text-xl text-center mb-6">Easily Correct or Creatively Alter Image Angles</p>
      <p class="mb-4">
        Quickly adjust the orientation of your photos with our Rotate Image tool. Whether you need to correct a photo
        that was taken at an angle or you want to creatively alter the perspective, our tool makes it simple to rotate
        images precisely. Rotate left, right, or flip your images with ease using our intuitive interface that provides
        real-time feedback.
      </p>

      <h3 class="text-2xl font-bold mt-12 mb-6">Why Use Rotate Image?</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 feature-page__specific">
        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/precision.png" alt="precise rotation" />
          <strong>Precise Control</strong>
          <p>Adjust your images to the exact degree needed, ensuring every photo is perfectly aligned as intended.</p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/creative.png" alt="creative flexibility" />
          <strong>Creative Flexibility</strong>
          <p>
            Explore new perspectives and enhance your photos by experimenting with different orientations and angles.
          </p>
        </div>

        <div class="flex flex-col space-y-4 items-center text-center">
          <img src="@/images/landing_page_icons/speed.png" alt="fast processing" />
          <strong>Instant Results</strong>
          <p>Our advanced technology allows for immediate changes, letting you see results instantly without delay.</p>
        </div>
      </div>

      <h3 class="text-2xl font-bold mt-12 mb-6">How It Works</h3>
      <ol class="list-decimal pl-5 mb-6">
        <li><strong>Upload Your Image:</strong> Drag and drop your image or upload it from your device.</li>
        <li>
          <strong>Choose Rotation:</strong> Select the desired rotation direction or manually enter a specific angle for
          precise adjustments.
        </li>
        <li>
          <strong>Apply & Preview:</strong> Apply the rotation and instantly preview the result to ensure the image
          meets your requirements.
        </li>
        <li>
          <strong>Download:</strong> Download the adjusted image, ready to use in any project or to share on any
          platform.
        </li>
      </ol>

      <p class="">
        Designed to perform flawlessly on all devices, our Rotate Image tool provides you with a quick, efficient way to
        manage your photo orientation directly online.
      </p>
    </div>
  </article>
</template>
