import { useNotification } from "@kyvg/vue3-notification";

export function useNotify() {
    const { notify } = useNotification()

    const pushNotify = (text, title) => {
        notify({
            text,
            title,
            duration: 5000
        })
    }
    const pushError = (text) => {
        notify({
            type: 'error',
            title: 'Error',
            text,
            duration: 5000
        })
    }

    return { pushNotify, pushError }
}
