import '../channels'

import '@hotwired/turbo-rails'
import '~/application.css'

import 'cropperjs/src/js/cropper'
import 'cropperjs/src/css/cropper.css'

// Font Awesome
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-free'
library.add(fas, far, fab)

// Vue
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from '%/router.js'
import App from '&/App.vue'
import Notifications from '@kyvg/vue3-notification'
// import AppBreadcrumbs from '&/AppBreadcrumbs.vue'
import VueSafeHTML from 'vue-safe-html'
// import AppAdSense from '&/adsense/AppAdSense.vue'

document.addEventListener('turbo:load', () => {
  const app = createApp(App)
  const pinia = createPinia()

  app.use(pinia)
  app.use(router)
  app.use(Notifications)
  app.use(VueSafeHTML)
  // app.component('AppBreadcrumbs', AppBreadcrumbs)
  // app.component('AppAdSense', AppAdSense)
  app.mount('#app')

  // ===== Sub-menu
  const submenuItems = document.querySelectorAll('.submenu-item')
  submenuItems.forEach((el) => {
    el.querySelector('a').addEventListener('click', () => {
      el.querySelector('.submenu').classList.toggle('hidden')
    })
  })
})
