<template>
  <div v-if="ready" class="feature">
    <FeatureMeta :feature="featureStore.feature" />

    <div v-if="featureStore.images.length === 0">
      <FeatureInitSection :feature="featureStore.feature" :multiple="false" />
      <PageCropImage />
    </div>

    <div v-else class="h-full">
      <LayoutDefault @finished="onFinished">
        <div class="max-w-3xl mx-auto">
          <img
            id="image"
            :src="getImageURL(featureStore.images[0])"
            :alt="featureStore.images[0].filename"
            class="shadow-2xl w-full max-w-full block"
          />
        </div>

        <template #aside>
          <form class="flex flex-col space-y-6 mt-8" @change="handleOptions">
            <div class="flex items-center justify-between">
              <label for="cropWidth" class="input-label">Width (px)</label>
              <input
                id="cropWidth"
                v-model="featureStore.options[slug].cropWidth"
                min="1"
                type="number"
                class="input-field w-32 text-right"
                placeholder="1024"
              />
            </div>
            <div class="flex items-center justify-between">
              <label for="cropHeight" class="input-label">Height (px)</label>
              <input
                id="cropHeight"
                v-model="featureStore.options[slug].cropHeight"
                min="1"
                type="number"
                class="input-field w-32 text-right"
                placeholder="512"
              />
            </div>
            <div class="flex items-center justify-between">
              <label for="positionX" class="input-label">Position X</label>
              <input
                id="positionX"
                v-model="featureStore.options[slug].positionX"
                min="0"
                type="number"
                class="input-field w-32 text-right"
                placeholder="0"
              />
            </div>
            <div class="flex items-center justify-between">
              <label for="positionY" class="input-label">Position Y</label>
              <input
                id="positionY"
                v-model="featureStore.options[slug].positionY"
                min="0"
                type="number"
                class="input-field w-32 text-right"
                placeholder="0"
              />
            </div>
          </form>

          <AppNotice class="mt-12">Hold the Shift key while resizing to lock the aspect ratio</AppNotice>
        </template>
      </LayoutDefault>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { useFeatureSetup } from '&/composables/useFeatureSetup'
import { getImageURL } from '%/utils'
import PageCropImage from '&/landing_pages/PageCropImage.vue'
import Cropper from 'cropperjs'
import FeatureMeta from '&/FeatureMeta.vue'

let cropper = null

const { ready, router, featureStore, slug, LayoutDefault, FeatureInitSection, AppNotice } = useFeatureSetup()

onMounted(() => {
  if (featureStore.images.length > 0) setupCropper()
})

watch(
  () => featureStore.images,
  (newImages) => {
    if (newImages[0]) setupCropper()
  },
  { deep: true }
)

function onFinished() {
  router.push({ name: 'CropImageResult' })
}

function handleOptions() {
  cropper.setData({
    width: featureStore.options[slug].cropWidth,
    height: featureStore.options[slug].cropHeight,
    x: featureStore.options[slug].positionX,
    y: featureStore.options[slug].positionY
  })
}

function setupCropper() {
  setTimeout(() => {
    const imageElement = document.getElementById('image')
    if (cropper) cropper.destroy()
    cropper = new Cropper(imageElement, {
      viewMode: 2,
      zoomable: false,
      crop(event) {
        featureStore.options[slug].cropWidth = Math.round(event.detail.width)
        featureStore.options[slug].cropHeight = Math.round(event.detail.height)
        featureStore.options[slug].positionX = Math.round(event.detail.x)
        featureStore.options[slug].positionY = Math.round(event.detail.y)
      }
    })
  }, 500)
}
</script>
