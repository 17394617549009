import consumer from './consumer'

const ua_ip = document.querySelector("meta[name='ua_ip']").getAttribute('content')

consumer.subscriptions.create(
  { channel: 'ImageProgressChannel', ua_ip: ua_ip },
  {
    connected() {},

    disconnected() {},

    received(data) {
      const messageEl = document.getElementById('progressMessage')
      if (messageEl) messageEl.innerText = data.message
    }
  }
)
