import {getCurrentFeatureSlug} from "%/utils";
import { useFeatureStore } from "%/stores/feature";
import { useNotify } from "&/composables/useNotify";

export function useSubmit() {
    const featureStore = useFeatureStore()
    const { pushError } = useNotify()

    const onSubmit = (onFinished) => {
        featureStore.submitImmediately = false
        featureStore.progressModal.show()
        featureStore
            .execute(getCurrentFeatureSlug())
            .then(() => {
                onFinished()
            })
            .catch((err) => {
                console.error(err)
                pushError(err.response?.data?.error)
            })
            .finally(() => featureStore.progressModal.hide())
    }

    return { onSubmit }
}
