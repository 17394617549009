<template>
  <div class="grid grid-cols-2 gap-8 md:gap-0 h-full">
    <main class="col-span-2 md:col-span-1">
      <div class="py-8 mx-auto px-4 lg:px-12 h-full flex flex-col justify-center">
        <div>
          <div class="flex flex-col space-y-8 items-center justify-center">
            <AppLogo/>
            <h1 class="gradient-title">Sign in</h1>
          </div>

          <div class="mt-12 max-w-[400px] mx-auto">
            <form class="default-form" @submit.prevent="onSignIn">
              <div>
                <AppInputField v-model="form.email" label="Email" placeholder="Email" type="email" autofocus required>
                  <i class="fa-solid fa-envelope"></i>
                </AppInputField>
              </div>

              <div>
                <AppInputField v-model="form.password" label="Password" placeholder="Password" type="password" required>
                  <i class="fa-solid fa-key"></i>
                </AppInputField>
              </div>

              <div class="flex justify-center">
                <button :disabled="store.sending" type="submit" class="btn btn--lg btn--primary">
                  <i class="fa-solid fa-right-to-bracket"></i>
                  <span>Sign in</span>
                </button>
              </div>

              <div class="flex flex-col space-y-4 mt-8">
                <router-link
                  :to="{ name: 'ForgotPassword' }"
                  class="link text-primary font-bold"
                >Forgot your password?</router-link
                >

                <div>
                  <span class="mr-1">Didn't receive the confirmation email?</span>
                  <router-link
                    :to="{ name: 'ResendConfirmationEmail' }"
                    class="link text-primary font-bold"
                  >Resend it</router-link
                  >
                </div>

                <div>
                  <span class="mr-1">Don't have an account yet?</span>
                  <router-link
                    :to="{ name: 'SignUp' }"
                    class="link text-primary font-bold"
                  >Create an account</router-link
                  >
                </div>
              </div>
            </form>

            <hr class="my-6 border border-solid border-gray-300" />
            <div class="">
              <OAuth/>
            </div>
          </div>

        </div>
      </div>
    </main>

    <aside class="col-span-2 md:col-span-1 bg-teal-50">
      <div class="py-8 mx-auto px-4 lg:px-12 h-full flex flex-col justify-center max-w-[640px]">
        <div>
          <img src="@/images/pages/auth_top.png" alt="Register to piceditor.io" class="w-2/3 mx-auto"/>
          <h1 class="hidden">{{ welcomeTexts[0] }}</h1>
          <div
            class="mt-12 font-extrabold text-transparent text-3xl bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600"
          >
            {{ welcomeText }}
          </div>
        </div>
      </div>
    </aside>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useAuth } from '&/composables/useAuth'

const { store, sessionStore, pushError, ForgotPassword, OAuth, AppInputField, AppLogo } = useAuth()

const welcomeTexts = [
  "Sign In to Access Advanced Photo Editing Tools",
  'Welcome back to piceditor.io - where creativity meets technology!',
  'Ready to continue your photo editing journey? Sign in to piceditor.io!',
  'Sign in now and keep transforming your photos with ease!',
  'Your creative toolkit awaits at piceditor.io - Sign in to continue!',
  'Return to your world of advanced photo editing - Sign in now!',
  'Dive back into your projects with piceditor.io - Sign in here!',
  'Sign in to access your dashboard and start editing!',
  'Continue enhancing your photos - Sign in to piceditor.io now!',
  'Get back to creating stunning visuals - Sign in and start editing!',
  'Your images deserve the best edits - Sign in to piceditor.io to continue your work!'
]
const welcomeText =
  welcomeTexts[Math.floor(Math.random() * welcomeTexts.length)]

const form = reactive({
  email: '',
  password: ''
})

const onSignIn = function() {
  store.sending = true
  sessionStore
    .signIn(form)
    .then(() => {
      window.location.href = '/'
    })
    .catch(err => {
      store.sending = false
      let error = err?.response?.data?.errors?.join('\n') || err?.message
      pushError(error)
    })
}
</script>
